// Lerp two hex colors
export default function (a, b, amount) {
  const ar = a >> 16
  const ag = a >> 8 & 0xff
  const ab = a & 0xff

  const br = b >> 16
  const bg = b >> 8 & 0xff
  const bb = b & 0xff

  const rr = ar + amount * (br - ar)
  const rg = ag + amount * (bg - ag)
  const rb = ab + amount * (bb - ab)

  return (rr << 16) + (rg << 8) + (rb | 0)
}
