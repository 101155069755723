/* global IntersectionObserver */
import DomComponent from 'abstractions/DomComponent'
import triggerAnimation from 'utils/trigger-animation'

export default class Filters extends DomComponent {
  didInit (props) {
    this.handleIntersection = this.handleIntersection.bind(this)
    this.state = {
      currentCover: null
    }
  }

  get hasVisibleCover () {
    return this.refs.cover.offsetParent !== null
  }

  didMount (props, state) {
    this.refs.items = this.refs.base.querySelectorAll('.projects__item')
    this.refs.cover = this.refs.base.querySelector('.projects__cover')
    this.refs.preloadObserver = new IntersectionObserver(this.handleIntersection)

    this.refs.items.forEach(item => {
      // Preload background image when item is in the viewport
      item.cover = document.createElement('a')
      item.preload = () => {
        if (!this.hasVisibleCover) return
        if (item.preloaded) return

        // Covers are clickable
        const link = item.querySelector('a')
        item.cover.href = link && link.href

        // Covers can be empty
        const url = item.getAttribute('data-cover-url')
        item.cover.style.backgroundPosition = item.getAttribute('data-cover-position') || 'center center'
        item.cover.style.backgroundImage = url ? `url(${url})` : ''

        item.cover.classList.add('cover-holder', 'is-hidden')
        this.refs.cover.appendChild(item.cover)

        item.preloaded = true
      }

      this.refs.preloadObserver.observe(item)

      item.addEventListener('mouseenter', () => this.display(item))
    })

    // Automatically display the first item at init
    this.display(this.refs.items[0])
  }

  display (item) {
    if (!item) return
    if (!this.hasVisibleCover) return

    window.requestAnimationFrame(() => {
      if (this.state.currentCover === item.cover) return

      if (this.state.currentCover) {
        this.state.currentCover.classList.add('is-hidden')
      }

      this.state.currentCover = item.cover

      // Make sure the item is correctly preloaded in case
      // IntersectionObserver did not trigger
      item.preload()

      item.cover.classList.remove('is-hidden')
      triggerAnimation(this.refs.cover)
    })
  }

  handleIntersection (entries) {
    entries.forEach(entry => {
      if (!entry.isIntersecting) return
      entry.target.preload()
    })
  }

  willUnmount () {
    this.refs.preloadObserver.disconnect()
  }
}
