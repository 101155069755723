import lozad from 'lozad'
import BarbaView from 'abstractions/BarbaView'
import Filters from 'components/Filters'
import Landing from 'components/Landing'
import Projects from 'components/Projects'
import isMobile from 'utils/is-mobile'

export default new BarbaView('home', {
  afterEnter: function ({ next }) {
    this.refs.landing = new Landing()
    this.refs.landing.hydrate(next.container.querySelector('.landing'))

    this.refs.filters = new Filters({ preventReload: true })
    this.refs.filters.hydrate(next.container.querySelector('.filters'))

    // Covers are lazyloaded by default
    const lazys = next.container.querySelectorAll('[data-lazyload]')
    this.refs.lazyload = lozad(lazys)
    this.refs.lazyload.observe()

    if (!isMobile()) {
      // Covers are handled by the Projects components
      this.refs.projects = new Projects()
      this.refs.projects.hydrate(next.container.querySelector('.projects'))
    }
  }
})
