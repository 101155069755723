import lozad from 'lozad'
import BarbaView from 'abstractions/BarbaView'

export default new BarbaView('about', {
  beforeEnter: function () {
    window.scrollTo(0, 0)
  },

  afterEnter: function ({ next }) {
    this.refs.menu = document.querySelector('.menu')
    this.refs.menu.classList.add('is-inverted')
    this.refs.menu.style.setProperty('--color', '#FFFFFF')

    const lazys = next.container.querySelectorAll('[data-lazyload]')
    this.refs.lazyload = lozad(lazys)
    this.refs.lazyload.observe()
  },

  afterLeave: function () {
    window.scrollTo(0, 0)
  }
})
