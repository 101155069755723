import lozad from 'lozad'
import BarbaView from 'abstractions/BarbaView'

import Filters from 'components/Filters'
import ProjectGallery from 'components/ProjectGallery'

export default new BarbaView('project', {
  beforeEnter: function () {
    window.scrollTo(0, 0)
  },

  afterEnter: function ({ next }) {
    // Menu
    this.refs.menu = document.querySelector('.menu')
    this.refs.menu.classList.remove('is-inverted')
    this.refs.menu.style.setProperty('--color', window.ENV.color)

    // Filters
    this.refs.filters = new Filters()
    this.refs.filters.hydrate(next.container.querySelector('.filters'))

    // Gallry
    this.refs.gallery = new ProjectGallery()
    this.refs.gallery.hydrate(next.container.querySelector('.project-gallery'))

    // Lazyload
    const lazys = next.container.querySelectorAll('[data-lazyload]')
    this.refs.lazyload = lozad(lazys, {
      loaded: e => {
        this.refs.gallery.updateOverflow()
      }
    })
    this.refs.lazyload.observe()
  }
})
