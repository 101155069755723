import DomComponent from 'abstractions/DomComponent'
import { clamp } from 'missing-math'
import { debounce } from 'tiny-throttle'
import lerpColors from 'utils/lerp-colors'

const HEX_ACCENT = parseInt(window.ENV.color.substr(1), 16)

export default class Filters extends DomComponent {
  didInit (props) {
    this.spyScroll = this.spyScroll.bind(this)
    this.updateDrapeau = this.updateDrapeau.bind(this)
    this.handleResize = debounce(this.updateDrapeau, 200)
  }

  didMount (props, state) {
    this.refs.menu = document.querySelector('.menu')
    this.refs.menu.classList.add('is-inverted')

    this.refs.message = this.refs.base.querySelector('.landing__message')
    this.refs.ajap = document.getElementById('ajap')

    this.spyScroll()
    this.updateDrapeau()
    window.addEventListener('resize', this.handleResize)
  }

  updateDrapeau () {
    let fontSize = 100
    let iter = 0
    do {
      fontSize -= 5
      this.refs.message.style.setProperty('--font-size', fontSize + 'px')
    } while (++iter < 100 && this.refs.message.scrollHeight > this.refs.message.offsetHeight)
  }

  spyScroll () {
    if (!this.mounted) return

    const t = window.scrollY / (window.innerHeight * 0.8)
    this.refs.menu.classList.toggle('is-inverted', t < 1)

    const toWhite = lerpColors(HEX_ACCENT, 0xFFFFFF, clamp(t, 0, 1))
    const toColor = lerpColors(0xFFFFFF, HEX_ACCENT, clamp(t, 0, 1))
    this.refs.base.style.setProperty('--color', '#' + toWhite.toString(16))
    this.refs.menu.style.setProperty('--color', '#' + toColor.toString(16))

    this.refs.ajap.style.opacity = clamp(1 - t, 0, 1)
    this.refs.ajap.style.transform = `translateX(${clamp(t, 0, 1) * 100}%)`

    window.requestAnimationFrame(this.spyScroll)
  }

  willUnmount () {
    window.cancelAnimationFrame(this.spyScroll)
    window.removeEventListener('resize', this.handleResize)
  }
}
