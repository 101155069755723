import 'nodelist-foreach'
import 'intersection-observer'
import smoothscroll from 'smoothscroll-polyfill'
import barba from '@barba/core'
import isMobile from 'utils/is-mobile'

import about from 'views/about'
import home from 'views/home'
import project from 'views/project'

smoothscroll.polyfill()

barba.init({
  debug: !window.ENV.production,
  prevent: ({ el }) => el.classList && el.classList.contains('no-barba'),
  views: [home, project, about],
  transitions: [{
    once: ({ next }) => {
      vhHack()
      updateFavicon()
      lazyFadeHack(next.container)
      document.body.classList.remove('is-loading')
    },
    enter: ({ next }) => {
      lazyFadeHack(next.container)
    }
  }]
})

// Update favicon color based on the color selected in the panel, using SVG
// replacement. Thanks Pricilla !
function updateFavicon () {
  const svg = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Ccircle cx='256' cy='256' r='256' fill='${window.ENV.color.replace('#', '%23')}' /%3E%3C/svg%3E`
  const favicons = document.querySelectorAll('link[rel*=icon]')
  favicons.forEach(favicon => favicon.setAttribute('href', svg))
}

// Lozad set a [data-loaded] attribute when loading image, but does not detect
// when image is fully loaded, which can cause incoherent fade-in animations
function lazyFadeHack (container) {
  const lazys = container.querySelectorAll('[data-lazyload]')
  lazys.forEach(image => {
    image.onload = () => image.setAttribute('data-fully-loaded', true)
  })
}

// This is used to get the right vh on mobile devices, see components/Landing.scss
function vhHack () {
  document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`)
}
if (!isMobile()) window.addEventListener('resize', vhHack)
