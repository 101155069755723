import normalize from 'normalize-wheel'
import DomComponent from 'abstractions/DomComponent'
import noop from 'utils/noop'

export default class Filters extends DomComponent {
  didInit (props) {
    this.handleClick = this.handleClick.bind(this)
    this.handleWheel = this.handleWheel.bind(this)
    this.onStick = props.onStick || noop
  }

  didMount (props, state) {
    // Hijack item click to avoid re-fetching the page
    if (props.preventReload) {
      this.refs.links = this.refs.base.querySelectorAll('.filters__item a')
      this.refs.links.forEach(a => a.parentNode.addEventListener('click', this.handleClick(a)))
    }

    // Scroll into view potential active item
    this.refs.scrollContainer = this.refs.base.querySelector('.filters__items')
    const active = this.refs.base.querySelector('.is-active')
    if (active) active.scrollIntoView({ inline: 'end' })

    // Enable scroll y to x mapping
    this.refs.base.addEventListener('wheel', this.handleWheel)
  }

  handleWheel (e) {
    const hasOverflow = this.refs.scrollContainer.scrollWidth > this.refs.scrollContainer.clientWidth
    if (!hasOverflow) return

    const normalized = normalize(e)
    const scrollIsVertical = Math.abs(normalized.pixelY) > Math.abs(normalized.pixelX)
    const delta = scrollIsVertical ? normalized.pixelY : normalized.pixelX

    this.refs.scrollContainer.scrollLeft += delta
    e.preventDefault()
  }

  handleClick (a) {
    const slug = (a.getAttribute('href') || '').match(/f:(.*)$/)[1]
    return e => {
      if (e.which > 1 || e.shiftKey || e.altKey || e.metaKey || e.ctrlKey) return

      // Store link activeness before resetting
      const isActive = a.parentNode.classList.contains('is-active')

      // Reset filters
      this.reset()
      e.preventDefault()
      e.stopPropagation()

      // Scroll to top of the container
      const stickedY = window.innerHeight - parseInt(window.getComputedStyle(this.refs.base).top || 0) * 3
      window.scrollTo({ top: stickedY, behavior: 'smooth' })

      // Stop here if filter was already active, effectively clearing it
      if (isActive) return

      // Disable projects__item not matching the filter
      const notMatching = document.querySelectorAll(`.projects__item:not([data-tags~=${slug}])`)
      if (!notMatching) return

      // Apply filter
      notMatching.forEach(el => el.classList.add('is-disabled'))
      this.refs.base.classList.add('has-active')
      a.parentNode.classList.add('is-active')
    }
  }

  reset () {
    this.refs.base.classList.remove('has-active')
    this.refs.base.querySelectorAll(`.is-active`).forEach(el => el.classList.remove('is-active'))
    document.querySelectorAll('.projects__item.is-disabled').forEach(el => el.classList.remove('is-disabled'))
  }

  willUnmount () {
    this.refs.base.removeEventListener('wheel', this.handleWheel)
  }
}
