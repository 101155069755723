import normalize from 'normalize-wheel'
import DomComponent from 'abstractions/DomComponent'
import Photoswipe from 'components/Photoswipe'

// Avoid flickering overflow state when scrolling too close to container ends
const OVERFLOW_THRESHOLD = 10 // px

export default class ProjectGallery extends DomComponent {
  didInit (props) {
    this.handleWheel = this.handleWheel.bind(this)
    this.updateOverflow = this.updateOverflow.bind(this)

    this.state = {
      hasLeftOverflow: false,
      hasRightOverflow: true
    }
  }

  didMount (props, state) {
    this.refs.scrollContainer = this.refs.base.querySelector('ul')
    this.refs.scrollContainer.addEventListener('wheel', this.handleWheel)

    this.refs.figures = this.refs.base.querySelectorAll('figure')
    this.refs.photoswipe = new Photoswipe({ figures: this.refs.figures })
    this.refs.photoswipe.hydrate(document.querySelector('.pswp'))

    this.updateOverflow()
    window.addEventListener('resize', this.updateOverflow)
  }

  handleWheel (e) {
    const hasOverflow = this.refs.scrollContainer.scrollWidth > this.refs.scrollContainer.clientWidth
    if (!hasOverflow) return

    const normalized = normalize(e)
    const scrollIsVertical = Math.abs(normalized.pixelY) > Math.abs(normalized.pixelX)
    const delta = scrollIsVertical ? normalized.pixelY : normalized.pixelX

    this.refs.scrollContainer.scrollLeft += delta
    this.updateOverflow()
    e.preventDefault()
  }

  updateOverflow () {
    const containerWidth = this.refs.base.clientWidth
    const contentWidth = this.refs.scrollContainer.scrollWidth
    const hasOverflow = contentWidth > containerWidth

    this.state.hasLeftOverflow = hasOverflow && this.refs.scrollContainer.scrollLeft > OVERFLOW_THRESHOLD
    this.state.hasRightOverflow = hasOverflow &&
      this.refs.scrollContainer.scrollLeft < (contentWidth - containerWidth) - OVERFLOW_THRESHOLD

    window.requestAnimationFrame(() => {
      this.refs.base.setAttribute('data-overflow-left', this.state.hasLeftOverflow)
      this.refs.base.setAttribute('data-overflow-right', this.state.hasRightOverflow)
    })
  }

  willUnmount () {
    window.removeEventListener('resize', this.updateOverflow)
    this.refs.scrollContainer.removeEventListener('wheel', this.handleWheel)
  }
}
